import React, { useEffect, useMemo, useState } from "react";
import BudgetCard from "./components/BudgetCard";
import { useGetUserBudgetQuery } from "../../../services/budget-service";
import { useParams } from "react-router-dom";
import BudgetData from "./components/BudgetData";

const Budget = () => {
  const { msisdn, username } = useParams();
  const { data: response, isLoading } = useGetUserBudgetQuery(msisdn || "");

  const [filteredBudgets, setFilteredBudgets] = useState([]);
  const [filterType, setFilterType] = useState("active");

  useEffect(() => {
    let filteredData = response?.data || [];
    switch (filterType) {
      case "active":
        filteredData = filteredData.filter(
          (budget) => budget.closed === false && budget.isPaymentMade === true
        );
        break;
      case "expired":
        filteredData = filteredData.filter(
          (budget) => budget.closed === true && budget.isPaymentMade === true
        );
        break;
      case "failed":
        filteredData = filteredData.filter(
          (budget) => budget.isPaymentMade === false
        );
        break;
      case "total":
      default:
        break;
    }
    setFilteredBudgets(filteredData);
  }, [response, filterType]);

  const activeBudgetsCount = useMemo(() => {
    return (
      response?.data?.filter(
        (budget) => budget.closed === false && budget.isPaymentMade === true
      )?.length || 0
    );
  }, [response]);

  const expiredBudgetsCount = useMemo(() => {
    return (
      response?.data?.filter(
        (budget) => budget.closed === true && budget.isPaymentMade === true
      )?.length || 0
    );
  }, [response]);

  const failedBudgetsCount = useMemo(() => {
    return (
      response?.data?.filter((budget) => budget.isPaymentMade === false)
        ?.length || 0
    );
  }, [response]);

  const totalBudget = useMemo(() => {
    return response?.data?.length || 0;
  }, [response]);

  const totalPayAmount = useMemo(() => {
    return (
      response?.data
        ?.filter((budget) => budget.isPaymentMade !== false)
        .reduce((total, budget) => total + budget.totalPayAmount, 0) || 0
    );
  }, [response]);

  const totalAmountCashedOut = useMemo(() => {
    return (
      response?.data?.reduce(
        (total, budget) => total + budget.amountCashedOut,
        0
      ) || 0
    );
  }, [response]);

  const handleCardClick = (type) => {
    setFilterType(type);
  };
  return (
    <div>
      <div className="py-4">
        <div className="w-full mx-auto">
          <div className="md:items-center md:flex">
            <p className="text-base font-bold text-gray-900 dark:text-white">
              {username} -
            </p>
            <p className="mt-1 text-base font-medium text-gray-500 dark:text-gray-300 md:mt-0 md:ml-2">
              User budget details
            </p>
          </div>

          <hr className="mt-8 border-gray-300" />
        </div>
      </div>
      <BudgetCard
        activeBudgetsCount={activeBudgetsCount}
        expiredBudgetsCount={expiredBudgetsCount}
        totalBudget={totalBudget}
        failedBudgetsCount={failedBudgetsCount}
        totalPayAmount={totalPayAmount}
        totalAmountCashedOut={totalAmountCashedOut}
        onCardClick={handleCardClick}
      />
      <BudgetData budgetData={filteredBudgets} isLoading={isLoading} />
    </div>
  );
};

export default Budget;
