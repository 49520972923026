import React from "react";
import Startup from "./utils/Startup";
import { GlobalStateProvider } from "./utils/GlobalStateContext";

const App = () => {
  return (
    <div>
      <GlobalStateProvider>
        <Startup />
      </GlobalStateProvider>
    </div>
  );
};

export default App;
