import React, { useState, useMemo } from "react";
import moment from "moment";
import DataTable from "../../../../components/DataTable";
import { useGetAllUsersQuery } from "../../../../services/user-service";
import DataTableHeader from "../../../../components/DataTableHeader";
import { MdVerified } from "react-icons/md";
import { IoIosWarning, IoMdCheckmarkCircleOutline } from "react-icons/io";
import { LuShieldClose } from "react-icons/lu";
import { AT, MTN, Telecel } from "../../../../assets";
import { useGlobalState } from "../../../../utils/GlobalStateContext";
import { TbReportMoney } from "react-icons/tb";
import { Link } from "react-router-dom";

const UsersData = () => {
  const { data: response, isLoading } = useGetAllUsersQuery();
  const { state } = useGlobalState(); // Destructure state and dispatch
  const { searchQuery } = state;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;

  const customers = response?.data || [];

  // Filter the data based on the search query
  const filteredData = useMemo(() => {
    if (!searchQuery) return customers;

    return customers.filter((user) =>
      Object.values(user).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, customers]);

  const totalPages = Math.ceil(filteredData.length / pageSize);
  const currentData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const getProviderLogo = (provider) => {
    switch (provider?.toLowerCase()) {
      case "vodafone":
        return Telecel;
      case "airtel":
        return AT;
      case "mtn":
        return MTN;
      default:
        return null;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "username",
        Cell: ({ row }) => {
          const { username } = row.original;
          return (
            <div className="flex items-center gap-2">
              <img
                src={`https://ui-avatars.com/api/?name=${username}&background=random&size=30`}
                alt="Avatar"
                className="rounded-full"
              />
              {username}
            </div>
          );
        },
      },
      {
        Header: "Provider",
        accessor: "provider",
        Cell: ({ value }) => {
          const logo = getProviderLogo(value);
          return (
            <div className="flex items-center gap-2">
              {logo && <img src={logo} alt={value} className="w-6 h-6" />}
              {value}
            </div>
          );
        },
      },
      {
        Header: "Msisdn",
        accessor: "msisdn",
      },
      {
        Header: "Country Code",
        accessor: "countryCode",
      },
      {
        Header: "Iso Code",
        accessor: "isoCode",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Ghana Card",
        accessor: "ghanaCard",
      },
      {
        Header: "Activated",
        accessor: "activated",
        Cell: ({ value }) =>
          value ? (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                <IoMdCheckmarkCircleOutline className="size-2.5" />
                Activated
              </span>
            </div>
          ) : (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500">
                <LuShieldClose className="size-2.5" />
                Not activated
              </span>
            </div>
          ),
      },
      {
        Header: "Email Verified",
        accessor: "isEmailVerified",
        Cell: ({ value }) =>
          value ? (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                <MdVerified className="size-2.5" />
                Verified
              </span>
            </div>
          ) : (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-yellow-100 text-yellow-800 rounded-full dark:bg-yellow-500/10 dark:text-yellow-500">
                <IoIosWarning className="size-2.5" />
                Not verified
              </span>
            </div>
          ),
      },
      {
        Header: "Date Created",
        accessor: (row) => moment(row.createdAt).format("MMMM Do YYYY"),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div className="flex gap-x-2">
            <Link
              to={`/users/view-budget/${row.original.username}/${row.original.msisdn}`}
              className="py-2 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-xl border border-gray-200 bg-white text-gray-700 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-800 dark:border-slate-600 dark:text-white dark:hover:bg-slate-700"
            >
              <TbReportMoney className="flex-shrink-0 size-3.5" />
              View budget
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <DataTableHeader />
      <DataTable
        columns={columns}
        data={currentData}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        isLoading={isLoading}
        pageSize={pageSize}
        totalElements={filteredData.length}
      />
    </div>
  );
};

export default UsersData;
