import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  HiMiniXMark,
  HiOutlineCog6Tooth,
  HiOutlineUsers,
} from "react-icons/hi2";
import { DarkLogo, Logo } from "../assets";
import { NavLink } from "react-router-dom";

const navigation = [{ name: "Users", href: "/users", icon: HiOutlineUsers }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Sidebar = ({ sidebarOpen, setSidebarOpen, collapsed, setCollapsed }) => {
  return (
    <>
      <Transition show={sidebarOpen}>
        <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <HiMiniXMark
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex h-screen grow flex-col gap-y-5 overflow-y-auto border dark:border-0 bg-white dark:bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                  <a
                    href="/"
                    className="flex gap-x-2 h-16 shrink-0 items-center"
                  >
                    <img
                      className="block dark:hidden w-28 "
                      src={DarkLogo}
                      alt="logo"
                    />
                    <img
                      className="hidden dark:block w-28 "
                      src={Logo}
                      alt="logo"
                    />
                  </a>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive
                                      ? "bg-[#e7e8e8] dark:bg-slate-700 text-black dark:text-white"
                                      : "text-gray-400 hover:text-black dark:hover:text-white hover:bg-[#e7e8e8] dark:hover:bg-slate-700",
                                    "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-medium"
                                  )
                                }
                              >
                                <item.icon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>

                      <li className="mt-auto">
                        <a
                          href="#"
                          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                        >
                          <HiOutlineCog6Tooth
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          Settings
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      {/* <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[280px] lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto  bg-white dark:bg-slate-800 px-6 pb-4">
          <a href="/" className="flex gap-x-2 h-20 shrink-0 items-center">
            <img
              className="block dark:hidden w-28 "
              src={DarkLogo}
              alt="logo"
            />
            <img className="hidden dark:block w-28 " src={Logo} alt="logo" />
          </a>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-[#e7e8e8] dark:bg-slate-700 text-black dark:text-white"
                              : "text-gray-400 hover:text-black dark:hover:text-white hover:bg-[#e7e8e8] dark:hover:bg-slate-700",
                            "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-medium"
                          )
                        }
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="mt-auto">
                <a
                  href="#"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                >
                  <HiOutlineCog6Tooth
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  Settings
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div> */}
      <div
        className={classNames(
          "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col transition-width duration-300",
          collapsed ? "lg:w-0" : "lg:w-[280px]"
        )}
      >
        <div
          className={classNames(
            "flex grow flex-col gap-y-5 overflow-y-auto bg-white  dark:bg-slate-800 px-6 pb-4",
            collapsed ? "hidden" : "block"
          )}
        >
          {/* Logo and Collapse Button */}
          <div className="flex items-center justify-between h-20 shrink-0">
            <a href="/" className="flex gap-x-2 items-center">
              <img
                className="block dark:hidden w-28"
                src={DarkLogo}
                alt="logo"
              />
              <img className="hidden dark:block w-28" src={Logo} alt="logo" />
            </a>
          </div>

          {/* Navigation */}
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-[#e7e8e8] dark:bg-slate-700 text-black dark:text-white"
                              : "text-gray-400 hover:text-black dark:hover:text-white hover:bg-[#e7e8e8] dark:hover:bg-slate-700",
                            "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-medium"
                          )
                        }
                      >
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="mt-auto">
                <a
                  href="#"
                  className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                >
                  <HiOutlineCog6Tooth
                    className="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  Settings
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
