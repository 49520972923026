import React from "react";
import { GoArrowRight } from "react-icons/go";
import { useLocation } from "react-router-dom";

const DataTableHeader = () => {
  const location = useLocation();

  const getTitle = (pathname) => {
    switch (pathname) {
      case "/users":
        return "Users";
      case "/quotes":
        return "Quotes";
      case "/claims":
        return "Claims";
      case "/commission":
        return "Commission";
      case "/customers":
        return "Customers";
      case "/policies":
        return "Policies";
      default:
        return "Dashboard";
    }
  };
  return (
    <div>
      <div className="w-full  mx-auto">
        <div className="bg-[#EBF1FC] bg-no-repeat bg-cover bg-center p-4 rounded-t-lg text-center flex justify-between items-center">
          <p className="me-2 inline-block font-bold text-2xl text-gray-800">
            {getTitle(location.pathname)}
          </p>
          <button className="relative inline-flex items-center justify-center p-2 px-4 py-2 overflow-hidden font-medium text-gray-800 transition duration-300 ease-out border-2 border-gray-800 rounded-full shadow-md group">
            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-gray-800 duration-300 -translate-x-full bg-transparent group-hover:translate-x-0 ease">
              <GoArrowRight className="w-6 h-6" />
            </span>
            <span className="absolute text-sm flex items-center justify-center w-full h-full text-gray-800 transition-all duration-300 transform group-hover:translate-x-full ease">
              Export
            </span>
            <span className="relative text-sm invisible">Export</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataTableHeader;
