import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";

export const userService = createApi({
  reducerPath: "userService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),

  tagTypes: ["Users"],
  endpoints: (build) => ({
    getAllUsers: build.query<BaseResponse<any>, void>({
      query: ( ) => ({
        url: "/user/users",
        method: "GET",
      }),
      providesTags: ["Users"],
    }),

  }),
});

export const {
  useGetAllUsersQuery,
 
} = userService;
