import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../core/app-constants";
import { BaseResponse } from "../models/response/base-response";

export const budgetService = createApi({
  reducerPath: "budgetService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),

  tagTypes: ["Budget"],
  endpoints: (build) => ({
    getUserBudget: build.query<BaseResponse<any>, string>({
      query: ( msisdn) => ({
        url: `/account/user-budget/${msisdn}`,
        method: "GET",
      }),
      providesTags: ["Budget"],
    }),

  }),
});

export const {
  useGetUserBudgetQuery,
} = budgetService;
