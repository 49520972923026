import React from "react";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useGlobalState } from "../utils/GlobalStateContext";

const SearchTable = () => {
  const { dispatch } = useGlobalState(); // Get dispatch function

  const handleSearchChange = (e) => {
    dispatch({ type: "SET_SEARCH_QUERY", payload: e.target.value }); // Dispatch action
  };
  return (
    <form className="relative flex flex-1" action="#" method="GET">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <HiMagnifyingGlass
        className="pointer-events-none dark:text-gray-100 absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full dark:bg-slate-800 dark:text-white border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder="Search..."
        type="search"
        name="search"
        onChange={handleSearchChange}
      />
    </form>
  );
};

export default SearchTable;
