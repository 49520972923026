import React, { useState, useMemo } from "react";
import moment from "moment";
import DataTable from "../../../../components/DataTable";
import { IoLockClosedSharp, IoLockOpen } from "react-icons/io5";
import { useGlobalState } from "../../../../utils/GlobalStateContext";
import { MdVerified } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";

const formatCurrency = (amount) => {
  if (amount == null) return 'GHS 0.00';
  return `GHS ${Number(amount).toLocaleString('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const BudgetData = ({ budgetData, isLoading }) => {
  const { state } = useGlobalState(); // Destructure state and dispatch
  const { searchQuery } = state;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;

  // Filter the data based on the search query
  const filteredData = useMemo(() => {
    if (!searchQuery) return budgetData;

    return budgetData.filter((user) =>
      Object.values(user).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, budgetData]);

  const totalPages = Math.ceil(filteredData?.length / pageSize);
  const currentData = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const columns = useMemo(
    () => [
      {
        Header: "Pay Frequency",
        accessor: "payFrequency",
      },
      {
        Header: "Pay Frequency Amount",
        accessor: (row) => formatCurrency(row.payFrequencyAmount),
      },
      {
        Header: "Start Date",
        accessor: (row) => moment(row.startDate).format("MMMM Do YYYY"),
      },
      {
        Header: "End Date",
        accessor: (row) => moment(row.endDate).format("MMMM Do YYYY"),
      },
      {
        Header: "Pay Time",
        accessor: "payTime",
      },
      {
        Header: "Total Pay Amount",
        accessor: (row) => formatCurrency(row.totalPayAmount),
      },
      {
        Header: "Remainder",
        accessor: (row) => formatCurrency(row.remainder),
      },
      {
        Header: "Available Amount To CashOut",
        accessor: (row) => formatCurrency(row.availableAmountToCashOut),
      },
      {
        Header: "Amount Cashed Out",
        accessor: (row) => formatCurrency(row.amountCashedOut),
      },
      {
        Header: "Payment Made",
        accessor: "isPaymentMade",
        Cell: ({ value }) =>
          value ? (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                <MdVerified className="size-2.5" />
                success
              </span>
            </div>
          ) : (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500">
                <IoIosWarning className="size-2.5" />
                fail
              </span>
            </div>
          ),
      },
      {
        Header: "Budget Status",
        accessor: "closed",
        Cell: ({ value }) =>
          value ? (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-yellow-100 text-yellow-800 rounded-full dark:bg-yellow-500/10 dark:text-yellow-500">
                <IoLockClosedSharp className="size-2.5" />
                Expired
              </span>
            </div>
          ) : (
            <div>
              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                <IoLockOpen className="size-2.5" />
                Active
              </span>
            </div>
          ),
      },
      {
        Header: "Date Created",
        accessor: (row) => moment(row.createdAt).format("MMMM Do YYYY"),
      },
    ],
    []
  );

  return (
    <div>
      <DataTable
        columns={columns}
        data={currentData}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        isLoading={isLoading}
        pageSize={pageSize}
        totalElements={filteredData?.length}
      />
    </div>
  );
};

export default BudgetData;
