import React, { useMemo } from "react";
import { FcDocument, FcExpired } from "react-icons/fc";
import { MdSmsFailed } from "react-icons/md";
import { VscCompassActive } from "react-icons/vsc";
import { BsCash } from "react-icons/bs";
import { IoCashOutline } from "react-icons/io5";
import CountUp from "react-countup";

const BudgetCard = ({
  activeBudgetsCount,
  expiredBudgetsCount,
  totalBudget,
  failedBudgetsCount,
  totalPayAmount,
  totalAmountCashedOut,
  onCardClick,
}) => {
  const formattedTotalPayAmount = useMemo(() => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GHS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(totalPayAmount)
      .replace("GHS", "")
      .trim();
  }, [totalPayAmount]);

  const formatteTotalAmountCashedOut = useMemo(() => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GHS",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(totalAmountCashedOut)
      .replace("GHS", "")
      .trim();
  }, [totalAmountCashedOut]);

  return (
    <div>
      <div className="py-6">
        <div className=" mx-auto w-full">
          <div className="grid grid-cols-2 gap-5 lg:gap-6 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            <div
              className="overflow-hidden transition ease-in-out cursor-pointer hover:scale-110 bg-white dark:bg-slate-800 border border-gray-200 dark:border-slate-600 rounded-xl"
              onClick={() => onCardClick("active")}
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="inline-flex items-center justify-center w-10 h-10 text-green-600 rounded-full bg-green-100">
                  <VscCompassActive className="w-6 h-6" />
                </div>
                <p className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
                  {activeBudgetsCount}
                </p>
                <p className="mt-0.5 text-sm font-medium text-gray-500 dark:text-gray-400">
                  Active Budget
                </p>
              </div>
            </div>

            <div
              className="overflow-hidden  bg-white dark:bg-slate-800 border transition ease-in-out cursor-pointer hover:scale-110 border-gray-200 dark:border-slate-600 rounded-xl"
              onClick={() => onCardClick("expired")}
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="inline-flex items-center justify-center w-10 h-10 text-red-600 rounded-full bg-red-100">
                  <FcExpired className="w-6 h-6" />
                </div>
                <p className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
                  {expiredBudgetsCount}
                </p>
                <p className="mt-0.5 text-sm font-medium text-gray-500 dark:text-gray-400">
                  Expired budget
                </p>
              </div>
            </div>

            <div
              className="overflow-hidden transition ease-in-out cursor-pointer hover:scale-110 bg-white dark:bg-slate-800 border border-gray-200 dark:border-slate-600 rounded-xl"
              onClick={() => onCardClick("total")}
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="inline-flex items-center justify-center w-10 h-10 text-blue-600 rounded-full bg-blue-100">
                  <FcDocument className="w-6 h-6" />
                </div>
                <p className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
                  {totalBudget}
                </p>
                <p className="mt-0.5 text-sm font-medium text-gray-500 dark:text-gray-400">
                  Total no. of budget(s)
                </p>
              </div>
            </div>

            <div
              onClick={() => onCardClick("failed")}
              className="overflow-hidden transition ease-in-out cursor-pointer hover:scale-110 bg-white dark:bg-slate-800 border border-gray-200 dark:border-slate-600 rounded-xl"
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="inline-flex items-center justify-center w-10 h-10 text-yellow-600 rounded-full bg-yellow-100 ">
                  <MdSmsFailed className="w-6 h-6" />
                </div>
                <p className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
                  {failedBudgetsCount}
                </p>
                <p className="mt-0.5 text-sm font-medium text-gray-500 dark:text-gray-400">
                  Failed budget(s)
                </p>
              </div>
            </div>
            <div className="overflow-hidden bg-white dark:bg-slate-800 border border-gray-200 dark:border-slate-600 rounded-xl">
              <div className="px-4 py-5 sm:p-6">
                <div className="inline-flex items-center justify-center w-10 h-10 text-violet-600 rounded-full bg-violet-100">
                  <BsCash className="w-6 h-6" />
                </div>
                <p className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
                  GHS {formattedTotalPayAmount}
                </p>
                <p className="mt-0.5 text-sm font-medium text-gray-500 dark:text-gray-400">
                  User top up (GHS)
                </p>
              </div>
            </div>
            <div className="overflow-hidden bg-white dark:bg-slate-800 border border-gray-200 dark:border-slate-600 rounded-xl">
              <div className="px-4 py-5 sm:p-6">
                <div className="inline-flex items-center justify-center w-10 h-10 text-purple-600 rounded-full bg-purple-100">
                  <IoCashOutline className="w-6 h-6" />
                </div>
                <p className="mt-2 text-xl font-bold text-gray-900 dark:text-white">
                  GHS {formatteTotalAmountCashedOut}
                </p>
                <p className="mt-0.5 text-sm font-medium text-gray-500 dark:text-gray-400">
                  User withdrawn (GHS)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetCard;
