// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyDGTGkMZzi6ai4X6h9AjpzW6VkOwkBNEEw",
    authDomain: "chopmoney-9c6dc.firebaseapp.com",
    projectId: "chopmoney-9c6dc",
    storageBucket: "chopmoney-9c6dc.appspot.com",
    messagingSenderId: "463025763170",
    appId: "1:463025763170:web:1928487c8e4d7a6940723b",
    measurementId: "G-EW416VWG0F"
  
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics };
