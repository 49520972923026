import React, { createContext, useReducer, useContext, ReactNode } from "react";

interface GlobalState {
  searchQuery: string;
}

interface GlobalStateContextType {
  state: GlobalState;
  dispatch: React.Dispatch<any>;
}

const initialState: GlobalState = {
  searchQuery: "",
};

const GlobalStateContext = createContext<GlobalStateContextType>({
  state: initialState,
  dispatch: () => null,
});

const globalStateReducer = (state: GlobalState, action: any): GlobalState => {
  switch (action.type) {
    case "SET_SEARCH_QUERY": // Add this case
      return { ...state, searchQuery: action.payload };
    default:
      return state;
  }
};

export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);
  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
