import React from "react";
import UsersData from "./components/UsersData";

const Users = () => {
  return (
    <div>
      <UsersData />
    </div>
  );
};

export default Users;
