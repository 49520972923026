import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import MainLayout from "../shared/MainLayout";
import Budget from "../pages/main/budget/Budget";
import Login from "../pages/auth/Login";
import Users from "../pages/main/users/Users";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/auth">
        <Route index element={<Navigate replace to="login" />} />
        <Route path="login" element={<Login />} />
      </Route>

      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate replace to="users" />} />
        <Route path="users" element={<Users />} />
        <Route path="users/view-budget/:username/:msisdn" element={<Budget />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
